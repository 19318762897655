import { FETCH_FUNNELS_QUERY } from './payloads/fetchFunnels';
import { ADD_BLANK_FUNNEL_MUTATION } from './payloads/addBlankFunnel';
import { UPDATE_FUNNEL_MUTATION } from './payloads/updateFunnel';
import { CREATE_PAGE_FROM_TEMPLATE_MUTATION } from './payloads/createPageFromTemplate';
import { GET_USER_DETAILS_QUERY } from './payloads/getUserDetails';
import { CREATE_USER_MUTATION } from './payloads/createUser';
import { STRIPE_SETUP_SUBSCRIPTION_MUTATION } from './payloads/setupSubscription';
import { STRIPE_GET_CUSTOMER_FROM_SESSION_QUERY } from './payloads/getCustomerFromSession';
import { FETCH_FUNNEL_PAGES_QUERY } from './payloads/fetchFunnelPages';
import { SAVE_PAGE_MUTATION } from './payloads/savePage';
import { GET_PAGE_INFO_QUERY } from './payloads/getPageInfo';
import { GET_NEXT_PAGE_QUERY } from './payloads/getNextPage';
import { CREATE_UPSELL_PAYMENT_INTENT } from './payloads/createUpsellPaymentIntent';
import { CREATE_ZAPPIER_CUSTOMER } from './payloads/createZuppierCustomer';
import { AF_CREATE_PAYMENT_INTENT } from './payloads/afCreatePaymentIntent';
import { AF_CREATE_SETUP_INTENT } from './payloads/afCreateSetupIntent';
import { RENAME_SUBPAGE } from './payloads/renameSubPage';
import { DELETE_SUBPAGE } from './payloads/deleteSubPage';
import { ADD_STATISTIC_EVENT } from './payloads/addStatisticEvent';
import { AF_CREATE_CHECKOUT_SESSION } from './payloads/afCreateCheckoutSession';
import { ADD_TO_MAILCHIMP_LIST } from './payloads/addToMailChimpList';
import { CREATE_BOOK_REQUEST } from './payloads/createBookRequest';
import { CREATE_BOOK_MUTATION } from './payloads/createBook';
import { UPDATE_BOOK_MUTATION } from './payloads/updateBook';
import { UPDATE_CHAPTER_MUTATION } from './payloads/updateChapter';
import { DELETE_CHAPTER_MUTATION } from './payloads/deleteChapter';
import { ADD_CHAPTER_MUTATION } from './payloads/addChapter';
import { REORDER_CHAPTERS_MUTATION } from './payloads/reorderChapters';
import { GET_BOOK_BY_ID } from './payloads/getBookById';
import { SET_COVER_IMAGE_MUTATION } from './payloads/setCoverImage';
import { GET_PAGE_EDIT_INFO_QUERY } from './payloads/getPageEditInfo';
import { FETCH_PRODUCTS } from './payloads/fetchProducts';
import { GET_BOOK_FOR_EDIT_BY_ID } from './payloads/getBookByIdForEdit';
import { REMOVE_FILES_FROM_S3 } from './payloads/removeFilesFromS3';
import { CHECK_SUBSCRIPTION_ACCESS } from './payloads/checkSubscriptionAccess';
import { GET_SECTIONS_BY_NAMES } from './payloads/getSectionsByNames';
import { CANCEL_IMAGE_GENERATION } from './payloads/cancelImageGeneration';
import { REFRESH_TOKEN } from './payloads/refreshToken';
import { GET_AI_PAGE } from './payloads/getAiPage';
import { UPDATE_AI_PAGE } from './payloads/udpateAiPage';
import { GET_ONE_AI_PAGE } from './payloads/getOneAiPage';
import { START_FREE_SUBSCRIPTION } from './payloads/startFreeSubscription';
import { CREATE_PAGE_MUTATION } from './payloads/createPage';
import { GET_CURRENT_USER_QUERY } from './payloads/getCurrentUserDetails';
import { GET_USER_PRODUCTS } from './payloads/getUserProducts';
import { CREATE_UPSELL_PAYMENT_INTENT2 } from './payloads/createUpsellPaymentIntent2';
import { GET_FUNNEL_PRODUCTS } from './payloads/getFunnelsWithPages';
import { GET_FUNNEL_PRODUCTS_BY_PAGE_ID } from './payloads/getFunnelProductsByPageId';
import { CREATE_PAYMENT_INTENT_2 } from './payloads/createPaymentIntent2';
import { CREATE_PAYMENT_SETUP } from './payloads/createPaymentSetup';
import { CREATE_PAYMENT_INTENT_3 } from './payloads/createPaymentIntent3';
import { GET_TEMPLATE_CATEGORIES } from './payloads/templates/getTemplateCategories';
import { CREATE_TEMPLATE_CATEGORY } from './payloads/templates/createTemplateCategory';
import { CREATE_TEMPLATE } from './payloads/templates/createTemplate';
import { UPDATE_TEMPLATE } from './payloads/templates/updateTemplate';
import { GET_SAAS_DESIGN } from './payloads/getSaasDesign';
import { GET_TEMPLATE } from './payloads/templates/getTemplate';

const queries = {
  GET_USER_DETAILS_QUERY,
  FETCH_FUNNELS_QUERY,
  CREATE_USER_MUTATION,
  STRIPE_SETUP_SUBSCRIPTION_MUTATION,
  STRIPE_GET_CUSTOMER_FROM_SESSION_QUERY,
  ADD_BLANK_FUNNEL_MUTATION,
  CREATE_PAGE_FROM_TEMPLATE_MUTATION,
  FETCH_FUNNEL_PAGES_QUERY,
  UPDATE_FUNNEL_MUTATION,
  SAVE_PAGE_MUTATION,
  GET_PAGE_INFO_QUERY,
  GET_NEXT_PAGE_QUERY,
  CREATE_UPSELL_PAYMENT_INTENT,
  CREATE_ZAPPIER_CUSTOMER,
  AF_CREATE_PAYMENT_INTENT,
  AF_CREATE_SETUP_INTENT,
  RENAME_SUBPAGE,
  DELETE_SUBPAGE,
  ADD_STATISTIC_EVENT,
  AF_CREATE_CHECKOUT_SESSION,
  ADD_TO_MAILCHIMP_LIST,
  CREATE_BOOK_REQUEST,
  CREATE_BOOK_MUTATION,
  UPDATE_BOOK_MUTATION,
  UPDATE_CHAPTER_MUTATION,
  DELETE_CHAPTER_MUTATION,
  ADD_CHAPTER_MUTATION,
  REORDER_CHAPTERS_MUTATION,
  GET_BOOK_BY_ID,
  GET_SAAS_DESIGN,
  SET_COVER_IMAGE_MUTATION,
  GET_PAGE_EDIT_INFO_QUERY,
  FETCH_PRODUCTS,
  GET_BOOK_FOR_EDIT_BY_ID,
  REMOVE_FILES_FROM_S3,
  CHECK_SUBSCRIPTION_ACCESS,
  GET_SECTIONS_BY_NAMES,
  CANCEL_IMAGE_GENERATION,
  REFRESH_TOKEN,
  GET_AI_PAGE,
  UPDATE_AI_PAGE,
  GET_ONE_AI_PAGE,
  START_FREE_SUBSCRIPTION,
  CREATE_PAGE_MUTATION,
  GET_CURRENT_USER_QUERY,
  GET_USER_PRODUCTS,
  CREATE_UPSELL_PAYMENT_INTENT2,
  GET_FUNNEL_PRODUCTS,
  GET_FUNNEL_PRODUCTS_BY_PAGE_ID,
  CREATE_PAYMENT_INTENT_2,
  CREATE_PAYMENT_SETUP,
  CREATE_PAYMENT_INTENT_3,
  GET_TEMPLATE_CATEGORIES,
  CREATE_TEMPLATE_CATEGORY,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  GET_TEMPLATE,
};

export default queries;
