import queries from 'graphql/queries';
import { getDomain } from 'Utils';
import { graphQlCall } from 'graphql/utils';
import { eventEmitter, rxSaasDesignData } from 'rx/rxState';

export const fetchSaasDesign = async () => {
  try {
    const { domain: initialDomain } = getDomain();

    const response = await graphQlCall({
      queryTemplateObject: queries.GET_SAAS_DESIGN,
      values: {
        domain:
          initialDomain === 'localhost:3000'
            ? 'app.dev.autofunnel.ai'
            : initialDomain,
      },
    });

    rxSaasDesignData.next(response);
  } catch (error: any) {
    eventEmitter.next({
      type: 'add-alert',
      payload: {
        type: 'error',
        text: 'Error fetching data',
        description: error.message,
      },
    });
  }
};
