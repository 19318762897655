import { BehaviorSubject, Subject } from 'rxjs';
import { IUserDetails } from 'types/user';
import { IBookData } from 'Components/books/types';
import { AiTemplate } from './actions/rxAiTemplateActions';

export const rxBlocks = new BehaviorSubject([]);
export const rxInitialBlocks = new BehaviorSubject([]);

export const rxInsertSection = new BehaviorSubject({});
export const rxReplaceSection = new BehaviorSubject({});
export const rxRemoveSection = new BehaviorSubject({});
export const rxReorderSection = new BehaviorSubject<{
  fromIndex: number;
  toIndex: number;
} | null>(null);
export const rxDuplicateSection = new BehaviorSubject({});

export const rxNavigation = new BehaviorSubject({});
export const rxPages = new BehaviorSubject({});
export const rxIsLoading = new BehaviorSubject('loading...');
export const rxCurrentPage = new BehaviorSubject('');
export const rxError = new BehaviorSubject(false);
export const rxAlerts = new BehaviorSubject([]);
export const rxBlocksFetched = new BehaviorSubject(false);
export const rxSelectedBlocks = new BehaviorSubject([]);

export const rxUserName = new BehaviorSubject('');
export const rxPageId = new BehaviorSubject('');
export const rxTemplateId = new BehaviorSubject('');
export const rxEditMode = new BehaviorSubject(false);
export const rxProjectName = new BehaviorSubject('');
export const rxPageName = new BehaviorSubject('');
export const rxViewWidth = new BehaviorSubject('Responsive');
export const rxViewZoom = new BehaviorSubject(1);
export const rxProducts = new BehaviorSubject([]);
export const rxProjectId = new BehaviorSubject('');
export const rxStripeKey = new BehaviorSubject('');

export const rxAiTemplate = new BehaviorSubject<AiTemplate>({} as AiTemplate);

export const rxDragData = new BehaviorSubject({});
export const rxCreationData = new BehaviorSubject({});

export const rxHistory = new BehaviorSubject([]);
export const rxHistoryStage = new BehaviorSubject(-1);

export const eventEmitter = new Subject<{ type: string; payload?: any }>();
export const rxSideMenu = new BehaviorSubject([]);
export const rxSections = new BehaviorSubject([]);

export const rxBlockIndexForSectionsPopup = new BehaviorSubject(-1);
export const rxBlockIndexForBook = new BehaviorSubject({});
export const rxManipulatorInUse = new BehaviorSubject(false);

export const rxExitModalEnabled = new BehaviorSubject(false);
export const rxShowExitModal = new BehaviorSubject(false);
export const rxExitModalData = new BehaviorSubject({});
export const rxTemplateData = new BehaviorSubject({});

export const rxPageSaved = new BehaviorSubject(false);
export const rxTemplateSaved = new BehaviorSubject(false);
export const rxCustomScripts = new BehaviorSubject(null);
export const rxTitle = new BehaviorSubject(null);

export const rxRerollImageData = new BehaviorSubject(null);

export const rxNewChapterIndex = new BehaviorSubject(null);
export const rxChapterIndexForDelete = new BehaviorSubject(null);
export const rxBookNeedSave = new BehaviorSubject(false);
export const rxBookDataForSave = new BehaviorSubject(null);
export const rxActiveChapterIndex = new BehaviorSubject(0);
export const rxChapterRenameData = new BehaviorSubject(null);
export const rxSelectBookImg = new BehaviorSubject('');
export const rxCurrentEditorBlockIndex = new BehaviorSubject(0);
export const rxPriceError = new BehaviorSubject(null);
export const rxBadContentAlert = new BehaviorSubject(false);
export const rxBookDetailsPopup = new BehaviorSubject<{
  open: boolean;
  bookData: IBookData | null;
}>({ open: false, bookData: null });

export const rxCurrentUser = new BehaviorSubject<IUserDetails | null>(null);
export const rxUpdatePlanPopup = new BehaviorSubject<{
  open: boolean;
  message?: string;
} | null>(null);

export const rxSaasDesignData = new BehaviorSubject(null);
