export const GET_SAAS_DESIGN = {
  operationName: 'getSaasDesign',
  query: `query getSaasDesign ($domain: String!) {
              getSaasDesign (domain: $domain){
                colorScheme
                domain
                logoUrl
                faviconUrl
                name
                alerts {
                  success
                  successHover
                  error
                  waiting
                }
                sideBar {
                  background
                  appHover
                  appSelected
                  divider
                  text
                  selectedText
                }
                accent {
                  accent
                  accentHover
                  darkAccent
                  darkAccentHover
                }
                apps {
                  name
                  key
                  enabled
                }
              }
            }`,
};
