import { ReactComponent as BookleIcon } from 'Assets/Book.svg';
import { rxSaasDesignData } from 'rx/rxState';
import { useObservable } from 'utils/UseObservable';
import { getDomain } from 'utils/functions';

import s from './logo.module.scss';

const Logo = () => {
  const saasDesignData = useObservable<any>(rxSaasDesignData);

  const bookleName =
    saasDesignData?.apps.find((app: any) => app.key === 'BOOKLE')?.name ??
    'Bookle';

  const redirectToHome = () => {
    const { domain } = getDomain();
    window.location.href = '//' + domain;
  };

  return (
    <div className={s.container} onClick={redirectToHome}>
      <BookleIcon />
      {bookleName}
    </div>
  );
};

export default Logo;
